import { render, staticRenderFns } from "./RuleNew.vue?vue&type=template&id=688f7e6e&scoped=true&"
import script from "./rule-new.component.ts?vue&type=script&lang=ts&"
export * from "./rule-new.component.ts?vue&type=script&lang=ts&"
import style0 from "./RuleNew.vue?vue&type=style&index=0&id=688f7e6e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "688f7e6e",
  null
  
)

export default component.exports